// FORM CONTROLE
$("#sendMail" ).submit(function(e) {
    e.preventDefault();
    var form = $("#sendMail");
    var url = Routing.generate('app_send_mail');
    var formData = new FormData($(this)[0]);
    
    $.ajax({
        type: "POST",
        data: formData,
        async: false,
        cache: false,
        contentType: false,
        enctype: 'multipart/form-data',
        processData: false,
        url: url,
        beforeSend: function() {
                $("#loader").css("display","block");
            },
        success: function(data)
        {
            $("#loader").css("display","none");
            location.reload();
        }
    });  
});
